import axios from 'axios';
import Jsona from 'jsona';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import store from '../../../store';
import { refreshToken } from '../../aws/cognito';

dayjs.extend(utc);

const dataFormatter = new Jsona();
const USERNAME = process.env.REACT_APP_FINIX_USERNAME;
const PASSWORD = process.env.REACT_APP_FINIX_PASSWORD;

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const updatePropertyPreferences = async (id, property, included, complianceLevel) => {
  try {
    const attributes = {
      id: `temp-id${id}`,
      acceptCreditCard: included.acceptCreditCard,
      preventPayments: included.preventPayment,
      requireFullPayment: included.fullPayment,
      absorbFees: included.absorbFees,
      absorbFeesPercent: included.percent ? included.percent : 100,
    };

    const data = {
      data: {
        type: 'properties',
        id,
        attributes: {
          id,
        },
        relationships: {
          paymentSettings: {
            data: {
              type: 'payment-settings',
              id: `temp-id${id}`,
              method: 'update',
            },
          },
        },
      },
      included: [
        {
          type: 'payment-settings',
          id: `temp-id${id}`,
          attributes,
        },
      ],
    };

    if (property) {
      data.data.attributes = {
        id,
        ...property,
        bedrooms: +included.bedrooms || 0,
        bathrooms: +included.bathrooms || 0,
        propertyGroup: +included.propertyGroup,
        parentPropertyId: +included.parentPropertyId,
      };
    }

    if (complianceLevel) {
      data.included[0].attributes.complianceLevel = included.complianceLevel;
    }

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        // Authorization: `Bearer ${oldApiToken}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/properties/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    console.log(error);
    const { response } = error;
    throw response;
  }
};

export const updatePaymentGateway = async () => {
  try {
    const data = {
      data: {
        type: 'payment-gateways',
        attributes: {
          paymentGateway: 'Wepay',
          action: 'registration_finished',
          params: {},
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        // Authorization: `Bearer ${oldApiToken}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/payment-gateways`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const updateUserProfile = async (cognitoToken, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'user',
        id: values.id,
        attributes: {
          firstName: values.firstName,
          lastName: values.lastName,
          address1: values.address1,
          zip: values.zipCode,
          city: values.city,
          state: values.state,
          website: '',
          cellPhone: values.mobilePhone,
          businessName: values.businessName,
          dob: values.birthdate,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/users/${values.id}`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateOnBoarding = async (cognitoToken, boardingData, step, values, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  const { merchant, owner } = boardingData;
  let dateOfBirth = '';

  if (step === 3) {
    if (values.dateOfBirth) dateOfBirth = values.dateOfBirth.format('YYYY-MM-DD');
    owner.fullName = `${values.firstName} ${values.lastName}`;
    owner.address.city = values.city;
    owner.dateOfBirth = dateOfBirth;
    owner.firstName = values.firstName;
    owner.lastName = values.lastName;
    owner.phone = values.mobilePhone;
    owner.ssn = values.ssn;
    owner.address.state = values.state;
    owner.address.zip = values.zipCode;
    owner.address.street = values.streetName;
    owner.address.num = values.streetNumber;
    merchant.website = values.website;
  }
  if (step === 4) {
    owner.percentOwnership = values.owner;
    merchant.ownershipType = values.ownership;
    merchant.legalName = values.legalBusinessName;
    merchant.dbaName = values.dbaName;
    merchant.businessEin = values.businessEin;
    merchant.businessStartDate = values.businessStartDate.format();
    merchant.address.num = values.bStreetNum;
    merchant.address.street = values.bAddress;
    merchant.address.city = values.bCity;
    merchant.address.state = values.bState;
    merchant.address.zip = values.bZipcode;
    merchant.bankRouting = values.routingNumber;
    merchant.bankAccount = values.bankAccountNumber;
  }

  const data = {
    data: {
      attributes: {
        processorId: values.processorId,
        compliance: true,
        signDate: dayjs.utc().format(),
        agent: process.env.REACT_APP_ENV === 'production' ? 'Payrent - FREE' : 'Payrent testing',
        office: process.env.REACT_APP_ENV === 'production' ? 'Payrent - PF' : 'Payrent testing',
        servicePlanId: values.servicePlanId,
        merchant: {
          ...merchant,
        },
        owner: {
          ...owner,
        },
      },
    },
  };

  const options = {
    method: 'PUT',
    headers: {
      'content-type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
    },
    data,
    url: `${process.env.REACT_APP_API_URL_V3}/boarding/${id}`,
  };
  try {
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

// deprecated - use removeRenterV3
export const removeRenter = async (propertyId, customerId, parentPropertyId, propertyGroup) => {
  try {
    const data = {
      data: {
        type: 'properties',
        id: propertyId, // Property id
        attributes: {
          parentPropertyId,
          propertyGroup,
        },
        relationships: {
          customerRenters: {
            data: [
              {
                type: 'customers',
                id: customerId, // customer id
                method: 'disassociate',
              },
            ],
          },
        },
      },
      included: [
        {
          type: 'customers',
          id: customerId,
          attributes: {
            id: customerId,
          },
        },
      ],
    };

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        // Authorization: `Bearer ${oldApiToken}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/properties/${propertyId}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const removeRenterV3 = async (
  propertyId,
  customerId,
  parentPropertyId,
  propertyGroup,
  cognitoToken,
  controller
) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const data = {
      data: {
        type: 'properties',
        id: propertyId, // Property id
        attributes: {
          parentPropertyId,
          propertyGroup,
        },
        relationships: {
          customerRenters: {
            data: [
              {
                type: 'customers',
                id: customerId, // customer id
                method: 'disassociate',
              },
            ],
          },
        },
      },
      included: [
        {
          type: 'customers',
          id: customerId,
          attributes: {
            id: customerId,
          },
        },
      ],
    };

    const options = {
      signal: controller?.signal,
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/properties/${propertyId}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw Error(error);
  }
};

export const updateRenter = async (cognitoToken, values, id, billingAccountId) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

    const data = {
      data: {
        type: 'default',
        id: Number(id),
        attributes: {
          cellPhone: values.mobilePhone,
          email: values.email,
          billingAccount: {
            id: billingAccountId,
            leaseStartDate: dayjs.utc(values.leaseStartDate).toISOString(),
            leaseEndDate: dayjs.utc(values.leaseEndDate).toISOString(),
            monthToMonthEndFlag: values.extendMonth,
            requireInsurance: values.requireInsurance,
          },
          propertyId: Number(values.propertyId),
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/users/renter/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updatePaymentSettings = async (id, values, propertyAdditionalFees, compliance) => {
  try {
    const attributes = {
      preventPayments: values.preventPayment,
      requireFullPayment: values.fullPayment,
      absorbFees: values.absorbFees !== undefined ? values.absorbFees : true,
      absorbFeesPercent: values.percent !== undefined ? Number(values.percent) : 100,
      acceptCreditCard: values.creditCardPayments,
    };

    const data = {
      data: {
        id,
        relationships: {
          paymentSettings: {
            data: {
              id: `temp-id${id}`,
              type: 'payment-settings',
              method: 'update',
            },
          },
        },
      },
      included: [
        {
          attributes,
          id: `temp-id${id}`,
          type: 'payment-settings',
        },
      ],
    };

    if (propertyAdditionalFees) {
      data.included[0].attributes.propertyFees = {
        security_deposit: values.securityDepositSwitch,
        security_deposit_amount: +values.securityDepositAmount || null,
        discount: values.daysEarlySwitch,
        discount_days: values.daysEarly || null,
        discount_amount: +values.discountAmountEarly || null,
        late_fee: +values.daysLateSwitch,
        late_fee_frequency: values.lateFeeFrequency || null,
        late_fee_days: +values.daysLateFee || null,
        late_fee_amount: values.lateFeeAmount || null,
        parking: values.parkingFeeSwitch,
        parking_amount: values.parkingFeeAmount || null,
        parking_frequency: values.periodParkingFee || null,
        move_in: values.moveInFeeSwitch,
        move_in_amount: +values.moveInFee || null,
        move_out: values.moveOutFeeSwitch,
        move_out_amount: +values.moveOutFee || null,
      };
    }

    if (compliance) {
      data.included[0].attributes.complianceLevel = values.complianceLevel;
    }

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/session/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

// deprecated - use updatePaymentSettingsV3
export const updateNotificationSettings = async (id, values) => {
  try {
    const data = {
      data: {
        id,
        relationships: {
          notificationSettings: {
            data: {
              id: `temp-id${id}`,
              type: 'notification-settings',
              method: 'update',
            },
          },
        },
      },
      included: [
        {
          type: 'notification-settings',
          id: `temp-id${id}`,
          attributes: {
            id: `temp-id${id}`,
            paymentConfirmations: values.paymentsConfirmations,
            paymentFailure: values.failedPayments,
          },
        },
      ],
    };

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/session/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateNotificationSettingsV3 = async (id, values, cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        id,
        relationships: {
          notificationSettings: {
            data: {
              id: `temp-id${id}`,
              type: 'notification-settings',
              method: 'update',
            },
          },
        },
      },
      included: [
        {
          type: 'notification-settings',
          id: `temp-id${id}`,
          attributes: {
            id: `temp-id${id}`,
            paymentConfirmations: values.paymentsConfirmations,
            paymentFailure: values.failedPayments,
          },
        },
      ],
    };

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/session/account-settings`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateFeedItem = async (cognitoToken, id, action, data) => {
  try {
    // console.log('ƒ updateFeedItem', id, action, data);
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const url = `${process.env.REACT_APP_API_URL_V3}/feed/${id}/${action}`;
    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };

    // if (process.env.REACT_APP_ENV === 'local') {
    //   data.pinned = true;
    //   options.data = data;
    // }

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    console.log(error);
    const { response } = error;
    throw response;
  }
};

export const updatePropertyGroup = async (cognitoToken, id, values) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'property-groups',
        attributes: {
          name: values.portfolioName,
          settings: {
            absorb_fees: values.absorbFees,
            property_fees: {
              security_deposit: values.securityDepositSwitch,
              security_deposit_amount: values.securityDepositAmount || null,
              discount: values.daysEarlySwitch,
              discount_days: +values.daysEarly || null,
              discount_amount: values.discountAmountEarly || null,
              late_fee: values.daysLateSwitch,
              late_fee_frequency: values.lateFeeFrequency || null,
              late_fee_days: +values.daysLateFee || null,
              late_fee_amount: values.lateFeeAmount || null,
              parking: values.parkingFeeSwitch,
              parking_amount: values.parkingFeeAmount || null,
              parking_frequency: values.periodParkingFee || null,
              move_in: values.moveInFeeSwitch,
              move_in_amount: values.moveInFee || null,
              move_out: values.moveOutFeeSwitch,
              move_out_amount: values.moveOutFee || null,
            },
            compliance_level: values.complianceLevel,
            prevent_payments: values.preventPayment,
            accept_credit_card: values.acceptCreditCard,
            absorb_fees_percent: values.percent,
            require_full_payment: values.fullPayment,
            express_settlement: values?.expressSettlement || false,
          },
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/property-groups/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateParentProperty = async (
  cognitoToken,
  parentPropertyId,
  propertyGroupId,
  locationData,
  values
) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'parent-properties',
        attributes: {
          propertyGroupId,
          address: !locationData.streetNumber
            ? locationData.address
            : `${locationData.streetNumber} ${locationData.address}`,
          city: locationData.city || '',
          googlePlaceId: locationData.google_place_id || '',
          state: locationData.state || '',
          zip: locationData.zip || '',
          acquisitionDate: values.acquisitionDate ? values.acquisitionDate.format('YYYY-MM-DD') : null,
          acquisitionPrice: values.acquisitionPrice,
          currentValue: values.currentValue,
          annualDebtService: values.annualDebtService,
          annualTaxes: values.annualTaxes,
          annualInsurance: values.annualInsurance,
          annualMaintCosts: values.annualMaintCosts,
          annualMgmtFees: values.annualMgmtFees,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/parent-properties/${parentPropertyId}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateLandlordProfile = async (totalUnits, rentalType, id, quickStart) => {
  try {
    const data = {
      data: {
        id,
        relationships: {
          landlordMeta: {
            data: {
              id: `temp-id${id}`,
              type: 'landlord-meta',
            },
          },
        },
      },
      included: [
        {
          type: 'landlord-meta',
          id: `temp-id${id}`,
          attributes: {
            system: 'PayrentLandlord',
            landlordProfile: {
              totalUnits,
              rentalType,
              quickStart,
            },
          },
        },
      ],
    };

    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      data,
      url: `${process.env.REACT_APP_API_URL}/session/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateLandlordProfileV3 = async (
  totalUnits,
  rentalType,
  id,
  quickStart,
  cognitoToken,
  controller
) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        id,
        relationships: {
          landlordMeta: {
            data: {
              id: `temp-id${id}`,
              type: 'landlord-meta',
            },
          },
        },
      },
      included: [
        {
          type: 'landlord-meta',
          id: `temp-id${id}`,
          attributes: {
            system: 'PayrentLandlord',
            landlordProfile: {
              totalUnits,
              rentalType,
              quickStart,
            },
          },
        },
      ],
    };

    const options = {
      signal: controller?.signal,
      method: 'PATCH',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/session/account-settings`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const achMicrodepositVerification = async (cognitoToken, id, propertyGroupId) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'payment-method',
        id,
        attributes: {
          authFlow: 'verification',
          propertyGroupId,
        },
      },
    };

    const options = {
      method: 'PUT',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      data,
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const updateFinixMerchant = async (cognitoToken, identityId, data) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  // console.log('Identidy ID');
  // console.log(identityId);
  try {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/${identityId}`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    const res = await axios(options);
    // console.log('[api.js] ƒ createFinixMerchant result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response;
  }
};

export const updateFinixComplianceForm = async (formId, data) => {
  try {
    const options = {
      method: 'PUT',
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/compliance-forms/${formId}`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data,
    };
    const res = await axios(options);
    // console.log('[api.js] ƒ updateFinixComplianceForm result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response;
  }
};

export const uploadFinixMerchantFile = async (url, data) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Basic ${Buffer.from(`${USERNAME}:${PASSWORD}`).toString('base64')}`,
      },
      url,
      data,
    };

    const res = await axios(options);
    // console.log('ƒ uploadFinixMerchantFile res', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response;
  }
};
