import React, { useState, useEffect, useRef } from 'react';
// import Tap from '@tapfiliate/tapfiliate-js';
import {
  confirmRegistration,
  authenticate,
  resendConfirmationCode,
  registerCognitoUser,
} from 'services/aws/cognito';

import jwt from 'jsonwebtoken';
// import LogRocket from 'logrocket';
// import Browsee from '@browsee/web-sdk';
import { attachPolicy } from 'services/api/common/create';
import { deleteUnconfirmedCognitoUser } from 'services/api/common/delete';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { getServicePlanName } from 'resources/helpers';
import setAuthToken from '../../../utils/setAuthToken';

// Components
import ConfirmationForm from './components/ConfirmationForm';

const ConfirmSignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const email = props.location.state?.userData.email;
  const password = props.location.state?.userData.password;
  // let phone = props.location.state?.userData.phone;
  const [phoneState, setPhoneState] = useState(props.location.state?.userData.phone);
  const firstName = props.location.state?.userData.firstName;
  const lastName = props.location.state?.userData.lastName;
  const businessName = props.location.state?.userData.businessName;
  const servicePlan = props.location.state?.userData.servicePlan;
  const apid = props.location.state?.userData.apid;
  const ipQualityScore = props.location.state?.userData.ipQualityScore;
  // const role = props.location.state?.userData.role;
  const isLandLord = props.location.state?.userData.isLandLord;
  const birthdate = props.location.state?.userData.birthdate;

  const [alertData, setAlertData] = useState({ visible: false, message: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  const [email, setEmail] = useState('');
  const isIdentified = useRef(false);

  useEffect(() => {
    if (!props.location.state?.userData.email) {
      history.push('/');
    }
    if (!updatePhoneNumber) {
      setEmail(props.location.state?.userData.email);
    }
  }, [email, history]);

  const handleSubmit = (confirmationCode, formEmail) => {
    console.log('Submit');
    console.log(formEmail);
    setIsLoading(true);
    props.setShowOverlay(true);
    // Sign-in the current user in the app
    const callback = async () => {
      // setIsLoading(false);
      let authError = false;

      const authResult = await authenticate(formEmail, password).catch((err) => {
        authError = err;
      });

      // Error -> stop execution
      if (authError) {
        setIsLoading(false);
        props.setShowOverlay(false);
        setAlertData({
          visible: true,
          message: authError.message || JSON.stringify(authError),
        });
        return;
      }

      // No errors -> redirect and remove temporal credentials from the memory

      const cognitoIdToken = jwt.decode(authResult.idToken.jwtToken);
      const AuthStr = `Bearer ${authResult.idToken.jwtToken}`;
      const Url = `${process.env.REACT_APP_API_URL_V3}/auth/legacy?id=${cognitoIdToken['custom:customerId']}`;

      try {
        await attachPolicy(authResult.identityCredentials.identityId, authResult.idToken.jwtToken);
      } catch (error) {
        console.log(error);
      }

      axios
        .get(Url, { headers: { Authorization: AuthStr } })
        .then(async (response) => {
          props.history.replace({
            ...props.location,
            state: undefined,
          });

          sessionStorage.setItem('payrent.session', response.data.data.attributes.token.token);
          // const temporalToken = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.  eyJjdXN0b21lcklkIjo1MSwiaWF0IjoxNTkyNTk2ODg4LCJleHAiOjE1OTQyNzU4ODU4ODN9.avksUqZNZ5M_2kAtuk19tj9xkXB86ZXFIshBB2OIwqw';
          if (cognitoIdToken['custom:apid']) localStorage.setItem('apid', cognitoIdToken['custom:apid']);
          setAuthToken(`Bearer ${response.data.data.attributes.token.token}`);

          // if (process.env.REACT_APP_ENV === 'production') {
          //   if (!isIdentified.current) {
          //     const role = cognitoIdToken['custom:landlord'] === 'true' ? 'landlord' : 'tenant';
          //     const customerData = {
          //       name: `${cognitoIdToken.given_name} ${cognitoIdToken.family_name}`,
          //       email: cognitoIdToken.email,
          //       subscriptionType: await getServicePlanName(cognitoIdToken['custom:servicePlan']),
          //       role,
          //       $tags: ['signup', role],
          //     };

          //     // LogRocket.identify(cognitoIdToken['custom:customerId'], customerData);
          //     Browsee.identify(cognitoIdToken['custom:customerId'], customerData);
          //     isIdentified.current = true;
          //   }
          // }

          if (cognitoIdToken['custom:landlord'] === 'true') {
            window.dataLayer.push({
              event: 'landlord_confirm',
              eventProps: {
                category: 'landlord_signup',
                action: 'confirm',
                label: 'step-2',
              },
            });
            // const servicePlan = cognitoIdToken['custom:servicePlan'];
            // Deprecated Tapfiliate
            // if (servicePlan) {
            //   if (servicePlan === '1' || servicePlan === '6' || servicePlan === '7' || servicePlan === '8') {
            //     Tap.customer(response.data.data.attributes.stripeCustomerId, {
            //       meta_data: { accountId: cognitoIdToken['custom:customerId'] },
            //     });
            //   } else {
            //     Tap.trial(response.data.data.attributes.stripeCustomerId, {
            //       meta_data: { accountId: cognitoIdToken['custom:customerId'] },
            //     });
            //   }
            // }

            dispatch({
              type: 'SET_USER_SESSION_DATA',
              userData: {
                cognito: cognitoIdToken,
              },
              role: 'landlord',
              cognitoTokens: authResult,
              oldTokens: {
                token: response.data.data.attributes.token.token,
                expires: response.data.data.attributes.token.expires,
              },
            });
            history.push('/landlord');
            setIsLoading(false);
            props.setShowOverlay(false);
          } else {
            dispatch({
              type: 'SET_USER_SESSION_DATA',
              userData: {
                cognito: cognitoIdToken,
              },
              role: 'tenant',
              cognitoTokens: authResult,
              oldTokens: {
                token: response.data.data.attributes.token.token,
                expires: response.data.data.attributes.token.expires,
              },
            });
            history.push('/tenant');
            setIsLoading(false);
            props.setShowOverlay(false);
          }
        })
        .catch((error) => {
          console.log(`error ${error}`);
          setIsLoading(false);
        });
    };

    const errorCallback = (err) => {
      setAlertData({
        visible: true,
        message: err.message || JSON.stringify(err),
      });
      setIsLoading(false);
      props.setShowOverlay(false);
    };

    try {
      confirmRegistration(email, confirmationCode, callback, errorCallback);
    } catch (err) {
      setIsLoading(false);
      props.setShowOverlay(false);
      setAlertData({
        visible: true,
        message: err.message || JSON.stringify(err),
      });
    }
  };

  const handleUpdatePhoneNumber = async (values) => {
    try {
      setIsLoading(true);
      console.log(values);
      const phoneNumber = values.phone.replace(/\s/g, '');
      // const newPhone = `+1${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
      const oldEmail = values.oldEmail;
      const email = values.email;
      setPhoneState(phoneNumber);
      await deleteUnconfirmedCognitoUser(oldEmail, phoneNumber);

      const callback = () => {
        setAlertData({
          type: 'success',
          visible: true,
          message: 'Your email was updated',
        });
        setTimeout(() => {
          setAlertData({
            visible: false,
          });
        }, 4000);
        setUpdatePhoneNumber(false);
        setIsLoading(false);
      };

      const errorCallback = (errorMessage) => {
        setIsLoading(false);
        props.setShowOverlay(false);
        setAlertData({
          visible: true,
          message: errorMessage,
        });
        return;
      };

      const userData = {
        firstName,
        lastName,
        businessName,
        servicePlan,
        apid,
        ipQualityScore,
        // phoneNumber: `1${phoneNumber}`,
        phoneNumber: phoneNumber.substring(1).replace(/-/g, ''),
        email,
        password,
        isLandLord,
        birthdate,
      };

      if (isLandLord) {
        window.dataLayer.push({
          event: 'landlord_change_phone',
          eventProps: {
            category: 'landlord_signup',
            action: 'change_phone',
            label: 'step-2b',
          },
        });
      }
      registerCognitoUser(userData, callback, errorCallback);
    } catch (error) {
      if (error) {
        setIsLoading(false);
        props.setShowOverlay(false);
        setAlertData({
          visible: true,
          message: error?.data?.errors[0]?.detail || 'Your request failed',
        });
        return;
      }
    }
  };

  const resendCode = async () => {
    try {
      setIsLoading(true);
      await resendConfirmationCode(email);
      setAlertData({
        type: 'success',
        visible: true,
        message: 'Your code was sent.',
      });
      setTimeout(() => {
        setAlertData({
          visible: false,
        });
      }, 4000);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setAlertData({
        type: 'error',
        visible: true,
        message: 'Your code was not sent, please try again',
      });
    }
  };

  return (
    <section className="screen screen--confirm-sign-up" data-testid="confirmSignUpTest">
      <ConfirmationForm
        handleSubmit={handleSubmit}
        handleUpdatePhoneNumber={handleUpdatePhoneNumber}
        resendConfirmationCode={resendCode}
        location={props.location} // For auto login
        isLoading={isLoading}
        // disabled={isLoading}
        alertData={alertData}
        updatePhoneNumber={updatePhoneNumber}
        setUpdatePhoneNumber={setUpdatePhoneNumber}
        phone={phoneState}
        email={email}
        setEmail={setEmail}
      />
    </section>
  );
};

export default ConfirmSignUp;
