import axios from 'axios';
import Jsona from 'jsona';
import store from '../../../store';
import { refreshToken } from '../../aws/cognito';
import { faker } from '@faker-js/faker';

const dataFormatter = new Jsona();

const fakerData = async (landlordId) => ({
  pinned: Array.from({ length: 10 }, () => ({
    createdAt: faker.date.past(),
    archived: faker.datatype.boolean(),
    flagged: faker.datatype.boolean(),
    landlordId,
    pinned: true,
    renterNameS: faker.person.fullName(),
    message: faker.lorem.sentence(5, 10),
    id: faker.string.uuid(),
    renterName: faker.person.fullName(),
    renterId: faker.number.int(),
    type: faker.helpers.arrayElement(['billing', 'payment', 'verification', 'schedule', 'chargeback']),
  })),
  feed: Array.from({ length: 100 }, () => ({
    createdAt: faker.date.past(),
    archived: faker.datatype.boolean(),
    flagged: faker.datatype.boolean(),
    landlordId,
    pinned: false,
    renterNameS: faker.person.fullName(),
    message: faker.lorem.sentence(5, 10),
    id: faker.string.uuid(),
    renterName: faker.person.fullName(),
    renterId: faker.number.int(),
    type: faker.helpers.arrayElement(['billing', 'payment', 'verification', 'schedule', 'chargeback']),
  })),
});

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const getProperties = async (pagination, page, size, search, filter, gqlFilter, controller) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/properties`;
    if (pagination) {
      url = `${url}?page[number]=${page}&page[size]=${size}&sort=-createdAt&include=paymentSettings,customerRenters,billingAccounts,propertyGroup`;
    } else {
      url = `${url}?sort=-createdAt&include=paymentSettings,customerRenters,billingAccounts,propertyGroup`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }
    if (filter) {
      url = `${url}&type=${filter}`;
    }
    if (gqlFilter) {
      url = `${url}&filter[gql]=(${gqlFilter})`;
    }

    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };
    const res = await axios(options);

    let response = '';
    const deserialized = dataFormatter.deserialize(res.data);
    if (pagination) {
      response = {
        items: deserialized,
        totalCount: res.data.meta.totalCount,
      };
    } else {
      response = deserialized;
    }

    return response;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPropertiesV3 = async (cognitoToken, parentPropertyId, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const url = new URL(`${process.env.REACT_APP_API_URL_V3}/properties`);

    if (parentPropertyId) url.searchParams.append('parentProperty', parentPropertyId);

    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw error?.response || error;
  }
};

export const getServicePlans = async (controller) => {
  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL_V3}/service-plans`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw error?.response || error;
  }
};

export const getBoardedApps = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/boarding`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw error?.response || error;
  }
};

export const getSessionDetails = async () => {
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        // Authorization: `Bearer ${oldApiToken}`,
      },
      url: `${process.env.REACT_APP_API_URL}/session?page[size]=1&include=paymentSettings,notificationSettings,property,billingAccounts,notificationSettings`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getOnboardingStatus = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/setup-status`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const fetchUserData = async (cognitoToken, id) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getBoardingStatus = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/profile/boarding`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getBoardingInformation = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/boarding/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getTransactions = async (
  page,
  size,
  filter,
  typeFilter,
  renterFilter,
  sort,
  search,
  dateRange,
  fieldsFilter,
  portfolioFilter
) => {
  let url = `${process.env.REACT_APP_API_URL}/billing-items?page[number]=${page}&filter[gql]=(${encodeURIComponent(
    filter
  )})`;

  const sortInclude = `&sort=${sort}&include=payment,billingCharge,property,customer&search=`;

  // if (typeFilter && !renterFilter) {
  //   url = `${url}${encodeURIComponent('+')}(${encodeURIComponent(typeFilter)})${sortInclude}`;
  // }

  if (renterFilter) {
    url = `${url}${encodeURIComponent('+')}(${encodeURIComponent(renterFilter)})${sortInclude}`;
  }

  if (!renterFilter) {
    url = `${url}${sortInclude}`;
  }

  if (search) {
    url = `${url}${search}`;
  }
  if (dateRange) {
    url = `${url}&range=${dateRange}`;
  }
  if (typeFilter) {
    url = `${url}&type=${typeFilter}`;
  }
  if (fieldsFilter) {
    url = `${url}&fields[property]=${fieldsFilter}`;
  }

  if (size) {
    url = `${url}&page[size]=${size}`;
  }

  if (portfolioFilter) {
    url = `${url}&fields[propertyGroup]=${portfolioFilter}`;
  }
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };
    const res = await axios(options);

    const response = {
      items: dataFormatter.deserialize(res.data),
      totalCount: res.data.meta.totalCount,
    };
    return response;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getSettlements = async (cognitoToken, page, size, statusFilter, sortOrder, dateRange, search) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    let url = `${process.env.REACT_APP_API_URL_V3}/settlements?page=${page}&size=${size}&sort_order=${sortOrder}&sort_by=created_at`;

    if (statusFilter) {
      url = `${url}&status=${statusFilter}`;
    }

    if (dateRange) {
      const dates = dateRange.split(':');
      url = `${url}&date_from=${dates[0]}&date_to=${dates[1]}`;
    }

    if (search) {
      url = `${url}&property_group_name=${search}`;
    }

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getFinixSettlements = async (
  cognitoToken,
  page,
  size,
  statusFilter,
  sortOrder,
  dateRange,
  search
) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    let url = `${process.env.REACT_APP_API_URL_V3}/finix/settlements?page=${page}&size=${size}&sort_order=${sortOrder}&sort_by=created_at`;

    if (statusFilter) {
      url = `${url}&status=${statusFilter}`;
    }

    if (dateRange) {
      const dates = dateRange.split(':');
      url = `${url}&date_from=${dates[0]}&date_to=${dates[1]}`;
    }

    if (search) {
      url = `${url}&property_group_name=${search}`;
    }

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };

    const res = await axios(options);

    const metaData = res.data.meta || {};
    const data = dataFormatter.deserialize(res.data);
    return { settlements: data.settlements, ...metaData };
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getFinixMerchantIdentity = async (cognitoToken, id, controller) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const url = `${process.env.REACT_APP_API_URL_V3}/finix/merchants/identities?property_group_id=${id}`;

    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };

    const res = await axios(options);
    console.log('res', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const getFinixComplianceForm = async (id) => {
  // console.log('ƒ getFinixComplianceForm', id);
  try {
    const url = `${process.env.REACT_APP_API_URL_V3}/finix/merchants/compliance-forms/${id}`;

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response || err;
  }
};

export const getSettlementTransfers = async (cognitoToken, url) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw error.response || error;
  }
};

export const getSettlementFundingTransfers = async (cognitoToken, url) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };
    const res = await axios(options);

    return dataFormatter.deserialize(res.data);
  } catch (error) {
    throw error.response || error;
  }
};

export const getRenters = async (page, size, sort, filter) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/landlord-renters?page[number]=${page}&page[size]=${size}&sort=${sort}&include=renterProperty,billingAccounts,paymentSettings`;

    if (filter) {
      url = `${url}&type=${filter}`;
    }

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };
    const res = await axios(options);

    const response = {
      items: dataFormatter.deserialize(res.data),
      totalCount: res.data.meta.totalCount,
    };
    return response;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getRentersV3 = async (cognitoToken, page, size, search, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${
        process.env.REACT_APP_API_URL_V3
      }/landlord-renters?page=${page}&limit=${size}&search=${encodeURIComponent(search)}`,
    };
    const res = await axios(options);

    const parsed = {
      items: dataFormatter.deserialize(res.data),
      total: res.data.meta.total,
    };
    return parsed;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getInvitedRenters = async (size) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/invites?page[size]=${size}&filter[gql]=converted:0&sort=-createdAt&include=property`;
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url,
    };
    const res = await axios(options);

    const response = {
      items: dataFormatter.deserialize(res.data),
      totalCount: res.data.meta.totalCount,
    };
    return response;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getBillingAccount = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/billing-accounts/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getActivityFeed = async (cognitoToken, filter) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    let url = `${process.env.REACT_APP_API_URL_V3}/feed`;

    if (filter) {
      url = `${url}${filter}`;
    }

    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };

    const res = await axios(options);

    let data = dataFormatter.deserialize(res.data);
    // console.log('data', data);

    // add seed data for local development
    // if (process.env.REACT_APP_ENV === 'local') {
    //   const userId = store().store.getState().session.userData.id;
    //   console.log('local env');
    //   const { feed, pinned } = await fakerData(userId);
    //   console.log('fakerData', feed, pinned);
    //   data.pinned = pinned;
    //   // data.feed = [...data.feed, ...feed];
    // }
    return data;
  } catch (error) {
    console.error(error);
    const { response } = error;
    throw response;
  }
};

// export const getSummary = async (cognitoToken, info) => {
//   const refreshed = await checkRefreshToken();
//   const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
//   try {
//     const options = {
//       method: 'GET',
//       headers: {
//         'content-type': 'application/vnd.api+json',
//         Accept: 'application/vnd.api+json',
//         Authorization: `Bearer ${token}`,
//       },
//       url: `${process.env.REACT_APP_API_URL_V3}/summary/${info}`,
//     };
//     const res = await axios(options);
//     return dataFormatter.deserialize(res.data);
//   } catch (error) {
//     const { response } = error;
//     throw response;
//   }
// };

export const getSummary = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/summary/dashboard`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getBillingDetails = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/${id}/billing-details`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getNewsFeed = async (source) => {
  try {
    const options = {
      cancelToken: source.token,
      method: 'POST',
      url: process.env.REACT_APP_NEWS_FEED_URL,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_FRESHSALES_KEY,
      },
      data: {
        url: 'https://payrenthelp.freshdesk.com/api/v2/solutions/folders/47000776196/articles',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    };
    const res = await axios(options);
    return res.data;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPropertyGroups = async (cognitoToken, page, limit, filters, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/property-groups?page=${page}&limit=${limit}`,
    };

    if (filters) {
      options.url = `${options.url}${filters}`;
    }
    const res = await axios(options);
    const deserialized = dataFormatter.deserialize(res.data);
    const response = {
      items: deserialized,
      totalCount: res.data.meta.total,
    };
    return response;
  } catch (error) {
    const { response = {} } = error;
    throw response;
  }
};

export const getParentProperties = async (cognitoToken, groupId, page, limit, search, cancelToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/parent-properties?property-group-id=${groupId}&page=${page}&limit=${limit}`,
    };
    if (cancelToken) {
      options.cancelToken = cancelToken.token;
    }

    if (search) options.url = `${process.env.REACT_APP_API_URL_V3}/parent-properties?search=${search}`;
    const res = await axios(options);
    const deserialized = dataFormatter.deserialize(res.data);
    const response = {
      items: deserialized,
      totalCount: res.data.meta.total,
    };
    return response;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getSilaBusinessRoles = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/sila/business-roles`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPropertyGroupOnboardingStatus = async (cognitoToken, id, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/property-groups/${id}/boarding-status`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPropertyGroupBoardingSubmit = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/profile/boarding?propertyGroup=${id}`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getStripeLink = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/stripe-connect/${id}/onboarding-link`,
    };

    const res = await axios(options);
    return res.data;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getLandlordPaymentMethods = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPlaidLinkToken = async (cognitoToken, id) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/${id}/link`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getPlaidOauthLinkToken = async (cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/payment-methods/plaid-link`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getSenderIdentity = async (cognitoToken, controller) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/esign/senders`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const getFinixMerchantVerification = async (id, controller) => {
  try {
    const options = {
      signal: controller?.signal,
      method: 'GET',
      headers: {
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/onboarding/verification/${id}`,
    };
    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};
