import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Form, Input, Row, Col, Select, DatePicker, Checkbox, Tooltip, Spin } from 'antd';
import { updateRenter } from 'services/api/landlordApi/update';
import { CloseSquareFilled, InfoCircleFilled } from '@ant-design/icons';
import Space from 'components/Space';
import AlertFade from 'components/AlertFade';
import Button from 'components/Button';
import Text from 'components/Text';
import PanelFooter from 'components/Panel/PanelFooter';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getProperties, getPropertiesV3 } from 'services/api/landlordApi/read';
import { Title, Subtitle } from '../../../../components/Panel/PanelTitle/PanelTitle';
import { Header } from '../../../../components/Panel/PanelHeader/PanelHeader';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import { disableLeaseEndDate, disableLeaseStartDate } from 'resources/FormValidators';

const { Option } = Select;
dayjs.extend(utc);

const RenterDetails = (props) => {
  // console.log('[RenterDetails.js] props:', props);
  const { currentPage, currentSize, sender } = props;
  const [message, setMessage] = useState({ type: 'success', message: '' });
  const session = useSelector((state) => state.session);
  const [form] = Form.useForm();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [populating, setPopulating] = useState(false);
  const [extendMonth, setExtendMonth] = useState(false);
  const [billingAccountId, setBillingAccountId] = useState(null);

  // const pagesRef = useRef(1);

  useEffect(() => {
    setPopulating(true);
    const formValues = {
      email: props.editRenter.email,
      propertyId: props.editRenter.billingAccounts[0].property.id,
      mobilePhone: props.editRenter?.cellPhone ? props.editRenter.cellPhone.replace('+1', '') : '',
    };

    const billingAccount = props.editRenter.billingAccounts.filter(
      (b) => b.state === 'ACTIVE' || b.state === 'PENDING'
    );
    if (billingAccount.length) {
      const leaseStartDate = billingAccount[0].leaseStartDate;
      const leaseEndDate = billingAccount[0].leaseEndDate;
      if (leaseStartDate) formValues.leaseStartDate = dayjs.utc(leaseStartDate);
      if (leaseEndDate) formValues.leaseEndDate = dayjs.utc(leaseEndDate);
      setBillingAccountId(billingAccount[0].id);
      if (!billingAccount[0].extendMonthToMonthFlag) {
        formValues.extendMonth = true;
        setExtendMonth(true);
      }
      if (billingAccount[0].requireInsurance) {
        formValues.requireInsurance = billingAccount[0].requireInsurance;
      }
    }

    fetchData(formValues.propertyId, formValues);
  }, []);

  // const getPaginatedProps = async (totalCount, quantity) => {
  //   const pages = Math.ceil(totalCount / quantity);
  //   const propResult = [];
  //   for (let i = 2; i < pages + 1; i++) {
  //     let result = await getProperties(true, i, quantity);
  //     propResult.push(result);
  //   }
  //   return propResult;
  // };

  const fetchData = async (selected, fValues) => {
    try {
      const propertiesRes = await getPropertiesV3(session.cognitoTokens.idToken.jwtToken, null);

      const propertyList = propertiesRes.filter((p) => {
        if (!Object.keys(p.billingAccount).length || p.id === selected) {
          return p;
        }
      });
      console.log('Empty properties: ', propertyList.length);
      setProperties([...propertyList]);
      setPopulating(false);
      form.setFieldsValue(fValues);
    } catch (error) {
      console.log('API ERROR:', error);
      setPopulating(false);
      displayAlert('Request failed.', 'warning');
    }
  };

  const displayAlert = (text, type) => {
    setMessage({
      message: text,
      type,
    });
    setTimeout(() => {
      setMessage({
        message: '',
      });
    }, 2000);
  };

  const onFinish = async (values) => {
    try {
      setIsLoading(true);
      const normalizePhone = `${values.mobilePhone.replace(/\s/g, '')}`;
      values.extendMonth = !extendMonth;
      values.mobilePhone = normalizePhone;
      await updateRenter(session.cognitoTokens.idToken.jwtToken, values, props.editRenter.id, billingAccountId);
      if (sender === 'renters') props.fetchRenters(currentPage, currentSize, '');

      setIsLoading(false);
      displayAlert('Renter successfully updated.', 'success');
      setTimeout(() => {
        if (props.fetchProperties) props.fetchProperties();
        props.setDrawerVisible(false);
        props.setShowEditRenterPanel(false);
        props.setEditRenter(null);
      }, 2100);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      const detail = error?.data?.errors[0]?.detail;
      displayAlert(detail || 'Request failed.', 'warning');
    }
  };
  const handleExtendMonth = (e) => {
    setExtendMonth(e.target.checked);
  };

  // refactored to resources/FormValidators
  // const disableLeaseEndDate = (current) => {
  //   if (form.getFieldValue('leaseStartDate')) {
  //     return current && current < dayjs(form.getFieldValue('leaseStartDate')).add(3, 'd');
  //   }
  //   return current && current < dayjs().startOf('day');
  // };

  return (
    <>
      <DrawerContainer>
        <StyledForm
          form={form}
          name="basic"
          initialValues={{
            remember: true,
            extendMonth: false,
            leaseStartDate: '',
            leaseEndDate: '',
            requireInsurance: false,
            mobilePhone: '',
          }}
          onFinish={onFinish}
          onFinishFailed={() => {}}
          layout="vertical"
          requiredMark={false}
        >
          <Header>
            <CloseSquareFilled
              style={{ fontSize: '25px', color: '#122C34' }}
              onClick={() => {
                props.setDrawerVisible(false);
                props.setShowEditRenterPanel(false);
              }}
              data-testid="closePanelTest"
            />
          </Header>
          <Title>Renter</Title>
          <Subtitle>Edit your renter´s details</Subtitle>
          <Space vertical={30} />
          <Spin spinning={populating}>
            <FormContentContainer className="content-container">
              <Form.Item label="Renter Email Address" name="email">
                <Input data-testid="emailInputTest" disabled={props.editRenter?.accountVerified} />
              </Form.Item>
              <Form.Item
                label="Renter Mobile Phone (optional)"
                name="mobilePhone"
                rules={[
                  {
                    // pattern: new RegExp('^[0-9]{6,}$'),
                    // supports ten characters without + sign
                    // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
                    pattern: new RegExp(/^[0-9]\s?(\d\s?){9,20}$/),
                    message: 'Please enter only numbers - min length 10',
                  },
                  {
                    message: 'Please input a valid phone number.',
                    max: 12,
                  },
                ]}
              >
                <PhoneInput disabled={props.editRenter?.accountVerified} />
              </Form.Item>
              <Form.Item
                label="Property Address"
                name="propertyId"
                rules={[{ required: true, message: 'Please select a property' }]}
              >
                <Select placeholder="Select property" onChange={() => {}} data-testid="selectTest">
                  {properties.length &&
                    properties.map((property, i) => (
                      <Option value={property.id} key={i}>{`${property.address} - #${property.address2}`}</Option>
                    ))}
                </Select>
              </Form.Item>
              <StyledRow gutter={16} justify="start">
                <Col span={12}>
                  <Form.Item
                    label="Lease Start"
                    name="leaseStartDate"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a date.',
                      },
                    ]}
                    shouldUpdate
                  >
                    <DatePicker
                      disabledDate={(current) => disableLeaseStartDate(current, form)}
                      format="MM/DD/YYYY"
                      placeholder="mm/dd/yyyy"
                      data-testid="leaseStartTest"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Lease End"
                    name="leaseEndDate"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a date.',
                      },
                    ]}
                    shouldUpdate
                  >
                    <DatePicker
                      disabledDate={(current) => disableLeaseEndDate(current, form)}
                      format="MM/DD/YYYY"
                      placeholder="mm/dd/yyyy"
                      data-testid="leaseEndTest"
                    />
                  </Form.Item>
                </Col>
              </StyledRow>
              <StyledItem name="extendMonth" getValueProps={() => extendMonth}>
                <Checkbox onClick={handleExtendMonth} checked={extendMonth} data-testid="extendMonthTest">
                  <Text size={16} color="#595959">
                    Remove renter on lease end date
                  </Text>
                  <Tooltip
                    placement="top"
                    title="Closes tenants billing account and removes them from the property"
                    trigger="click"
                  >
                    <InfoCircleFilled style={{ marginLeft: '8px' }} data-testid="ccTooltip" />
                  </Tooltip>
                </Checkbox>
              </StyledItem>
              <StyledItem name="requireInsurance" valuePropName="checked">
                <Checkbox>
                  <Text size={16} color="#595959">
                    Require Renters Insurance
                  </Text>
                </Checkbox>
              </StyledItem>
            </FormContentContainer>
          </Spin>
          <PanelFooter>
            <AlertFade
              type={message.type}
              isVisible={!!message.message}
              message={message.message}
              alertPosition="absolute"
              position="absolute"
              minHeight="100"
              width={90}
              bottom={82}
            />

            <Space vertical={20} />
            <Form.Item>
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  color="violet"
                  loading={isLoading}
                  alignment="center"
                  data-testid="buttonTest"
                >
                  SAVE
                </Button>
              </div>
            </Form.Item>
          </PanelFooter>
        </StyledForm>
      </DrawerContainer>
    </>
  );
};

const DrawerContainer = styled.div`
  max-width: 100%;
  height: 100%;
  border-radius: 24px;
  .content-container {
    height: 100%;
  }
  input {
    font-size: 16px;
  }
`;

const StyledItem = styled(Form.Item)``;

const FormContentContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  height: 50px;
  padding: 0px 25px 0px 25px;
  @media screen and (max-width: 400px) {
    padding: 0px 20px 0px 20px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const StyledRow = styled(Row)``;

export default RenterDetails;
