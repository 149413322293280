import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import styledTheme from 'styles/styledComponents/variables';
import {
  PlusSquareOutlined,
  SearchOutlined,
  DeleteRowOutlined,
  CaretDownFilled,
  CaretRightFilled,
} from '@ant-design/icons';
import { Space, Tabs, theme, Tooltip } from 'antd';
import moment from 'moment';
import Table from 'components/Table/Table';
import CardWithTitle from 'components/CardWithTitle';
import Button from 'components/Button/Button';
import SearchColumn from 'screens/Landlord/components/SearchColumn';

import DateRangeFilter from '../DateRangeFilter';
import FinixDetailsTable from './FinixDetailsTable/FinixDetailsTable';
import DetailsPending from './FinixDetailsTable/components/DetailsPending';
import Tag from 'components/Tag/Tag';
import IconButton from 'components/uielements/IconButton/IconButton';
import TransactionDescription from './TransactionDescription/TransactionDescription';

const DesktopTable = (props) => {
  const userData = useSelector((store) => store.session.userData);
  const { token } = theme.useToken();

  const {
    transactions,
    handleTableChange,
    itemType,
    currentPage,
    truncateString,
    handleClickDelete,
    deleteText,
    setDeleteText,
    dropdownVisible,
    setDropdownVisible,
    onExpand,
    expandedKeys,
    typeFilterMenu,
    renterFilterMenu,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleDateRangeFilter,
    handleResetDateRangeFilter,
    statusFilterMenu,
    dateFilterMenu,
    setDrawerVisible,
    setShowTransactionPanel,
    propertyGroupsMapped,
    portfolioFilterMenu,
    handleRefund,
    resetDropdown,
    setCurrentPage,
    loadSettlements,
    settleExpandedKeys,
    settlements,
    setSettleExpandedKeys,
    setExpandedKeys,
    handleSettlementSelect,
    onSettleExpand,
    settlePage,
    handleSettleTableChange,
    currentPageRef,
    activeTab,
    onTabClick,
    settleStartDate,
    setSettleStartDate,
    settleEndDate,
    setSettleEndDate,
    setSettlePage,
    settlePageRef,
    handleSettleDateRangeFilter,
    handleSettleResetDateRangeFilter,
    settleDateFilterMenu,
    handleSettleSearch,
    handleSettleReset,
    setSettleSearchText,
    settleSearchText,
    searchedColumn,
  } = props;
  // console.log('[DesktopTable.js] props', props.settlements);
  const searchInput = useRef(null);

  const dataMapping = (data) => {
    const mapped = data.map((d, i) => {
      let status = '';
      // if (d.state === 'PROCESSED') {
      //   status = 'processed';
      // } else if (d.state === 'NOT_PROCESSED') {
      //   status = 'failed';
      // } else if (d.state === 'PROCESSING') {
      //   status = 'pending';
      // } else if (d.state === 'DELAYED_PROCESSED') {
      //   status = 'scheduled';
      // } else if (d.state === 'POSTED') {
      //   status = 'posted';
      // }

      switch (true) {
        case d.refType === 'BillingCharge' && d.state === 'POSTED':
          status = d.billingItemType === 'CHARGE' ? 'billed' : 'credited';
          break;
        case d.refType === 'BillingCharge' && d.state === 'DELAYED_PROCESSED':
          status = 'scheduled';
          break;
        case d.refType === 'BillingCharge' && d.state === 'PROCESSING':
          status = 'pending';
          break;
        // case d?.billingCharge?.chargeType === 'REFUND':
        //   status = 'refund';
        //   break;
        case d.refType === 'Payment' && d.payment.status === 'COMPLETED':
          status = 'paid';
          break;
        case d.refType === 'Payment' && d.payment.status === 'PENDING':
          status = 'pending';
          break;
        case d.refType === 'Payment' && d.payment.status === 'ERROR':
        case d.refType === 'Payment' && d.payment.status === 'SCHEDULED':
        case d.refType === 'Payment' && d.payment.status === 'FAILED':
          status = 'failed';
          break;
        case d.refType === 'Payment' && d.payment.status === 'CANCELLED':
          status = 'cancelled';
          break;
        case d.refType === 'Payment' && d.payment.status === 'REFUNDED':
          status = 'refunded';
          break;
        default:
          break;
      }

      let settle_date = '-';
      let settle_amt = '-';
      if (d.payment && d.payment.transactionMeta) {
        settle_date = d.payment.transactionMeta.settlement_date;
        settle_amt = d.payment.transactionMeta.net_settlement;
      }

      const object = {
        key: d.finixId || i,
        date: moment(d.orderDate).utc().format('MM-DD-YYYY'),
        type: d.billingCharge ? itemType(d) : d.refType,
        refType: d.refType,
        address: `${d?.property[0]?.address}, #${d?.property[0]?.address2}`,
        portfolio: d?.property[0].propertyGroup?.name,
        renter: d?.customer.length ? `${d?.customer[0]?.firstName} ${d.customer[0]?.lastName}` : 'N/A',
        amount: (
          <span
            style={{
              color:
                d.billingItemType === 'CREDIT' ||
                (d.billingItemType === 'CREDIT' && d.state === 'DELAYED_PROCESSED')
                  ? 'green'
                  : 'red',
            }}
          >
            {(d.billingItemType === 'CREDIT' ||
              (d.billingItemType === 'CREDIT' && d.state === 'DELAYED_PROCESSED')) &&
              '-'}
            ${d.billingCharge ? d.billingCharge.amount.toFixed(2) : d.payment.amount}
          </span>
        ),
        status,
        transactionId: d.id,
        method: d.payment ? d.payment.paymentMethodType : '',
        fee: d.payment ? d.payment.fee.amount : 0,
        landlordAbsorbPercent: d.payment ? d.payment.fee.landlordAbsorbPercent : 0,
        settlementDate: settle_date,
        netSettlement: settle_amt,
        memo: d.memo,
        renterData: d?.customer.length ? d.customer : [],
        billingItem: d,
      };
      return object;
    });
    return mapped;
  };

  const dataSource = dataMapping(transactions.items);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      defaultSortOrder: 'descend',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      filteredValue: dateFilterMenu,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateRangeFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          handleResetDateRangeFilter={handleResetDateRangeFilter}
          setCurrentPage={setCurrentPage}
          currentPageRef={currentPageRef}
          handleDateRangeFilter={handleDateRangeFilter}
        />
      ),
      filterMultiple: true,
      width: '13%',
    },
    {
      title: 'Category',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      filteredValue: typeFilterMenu,
      defaultFilteredValue: typeFilterMenu,
      filters: [
        {
          text: 'Credit',
          value: 'CREDIT',
        },
        {
          text: 'Payment',
          value: 'PAYMENT',
        },
        {
          text: 'Security Deposit',
          value: 'SECURITY_DEPOSIT',
        },
        {
          text: 'Fee',
          value: 'MISC_FEE',
        },
        {
          text: 'Rent Charge',
          value: 'RENT',
        },
        {
          text: 'Refund',
          value: 'REFUND',
        },
        {
          text: 'Utility',
          value: 'UTILITY',
        },
        {
          text: 'Late Fee',
          value: 'LATE_FEE',
        },
        {
          text: 'Deposit',
          value: 'DEPOSIT',
        },
        {
          text: 'Discount',
          value: 'DISCOUNT',
        },
        {
          text: 'Parking',
          value: 'PARKING',
        },
        {
          text: 'Move In',
          value: 'MOVE_IN',
        },
        {
          text: 'Move Out',
          value: 'MOVE_OUT',
        },
        {
          text: 'Charge Back',
          value: 'CHARGEBACK',
        },
      ],
    },
    {
      title: 'Portfolio',
      dataIndex: 'portfolio',
      key: 'portfolio',
      filteredValue: portfolioFilterMenu,
      defaultFilteredValue: portfolioFilterMenu,
      filters: propertyGroupsMapped,
      width: '15%',
    },
    { title: 'Address', dataIndex: 'address', key: 'address', width: '30%' },
    {
      title: 'Renter',
      dataIndex: 'renter',
      key: 'renter',
      defaultFilteredValue: renterFilterMenu,
      width: '20%',
      // filters: renterFilter(renters.items),
    },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', width: '10%', align: 'right' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status) => {
        const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

        const StatusColor = {
          pending: 'gold',
          processed: 'green',
          posted: 'green',
          credited: 'green',
          billed: 'green',
          paid: 'green',
          failed: 'red',
          scheduled: 'blue',
          refund: 'orange',
        };
        return <Tag color={StatusColor[status]}>{statusCapitalized}</Tag>;
      },
      filteredValue: statusFilterMenu,
      filters: [
        {
          text: 'Scheduled',
          value: 'DELAYED_PROCESSED',
        },
        {
          text: 'Pending/Paid',
          value: 'PROCESSED',
        },
        {
          text: 'Charge/Credit',
          value: 'POSTED',
        },
        {
          text: 'Failed',
          value: 'NOT_PROCESSED',
        },
      ],
    },
    {
      title: 'Actions',
      key: 'action',
      align: 'center',
      width: '17%',
      render: (text, record) => {
        return (
          <Space size="middle">
            <IconButton
              data-testid={`deleteTransactionButtonTest`}
              toolTitle={'Delete Transaction'}
              showPopconfirm
              popTitle={'Really Delete transaction?'}
              handleClick={(e) => handleClickDelete(e, record.transactionId)}
              icon={<DeleteRowOutlined />}
              disabled={record.refType === 'Payment' || record.status === 'refund'}
              loading={record.loading}
            />
          </Space>
        );
      },
    },
  ];

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <SearchColumn
        name="property group"
        dataIndex={dataIndex}
        setSelectedKeys={setSelectedKeys}
        selectedKeys={selectedKeys}
        confirm={confirm}
        clearFilters={clearFilters}
        handleSearch={handleSettleSearch}
        handleReset={handleSettleReset}
        searchInput={searchInput}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[settleSearchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const settlementsColumns = [
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'date',
      defaultSortOrder: 'descend',
      // filteredValue: settleDateFilterMenu,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateRangeFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
          startDate={settleStartDate}
          setStartDate={setSettleStartDate}
          endDate={settleEndDate}
          setEndDate={setSettleEndDate}
          handleResetDateRangeFilter={handleSettleResetDateRangeFilter}
          setCurrentPage={setSettlePage}
          currentPageRef={settlePageRef}
          handleDateRangeFilter={handleSettleDateRangeFilter}
        />
      ),
      filterMultiple: true,
      width: '10%',
    },
    {
      title: 'Settled',
      dataIndex: 'settlementDate',
      key: 'date',
      width: '10%',
    },
    {
      title: 'Property Group',
      dataIndex: 'propertyGroupName',
      key: 'propertyGroupName',

      ...getColumnSearchProps('propertyGroupName'),
    },
    // {
    //   title: 'Payments',
    //   dataIndex: 'grossSettlement',
    //   key: 'totalPayments',
    //   width: '13%',
    // },
    // {
    //   title: 'Transaction Fees',
    //   dataIndex: 'adjustments',
    //   key: 'adjustments',
    //   width: '13%',
    // },
    {
      title: 'Settlement Fees',
      dataIndex: 'totalSettlementFees',
      key: 'fees',
      width: '13%',
      render: (text) => `$${Number(text).toFixed(2)}`,
    },
    {
      title: 'Net Deposit',
      dataIndex: 'netDeposit',
      key: 'netDeposit',
      width: '10%',
      render: (text, record) => `$${Number(text).toFixed(2)}`,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '5%',
      render: (status) => {
        const statusCapitalized = status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
        const StatusColor = {
          completed: 'green',
          failed: 'red',
          pending: 'gold',
        };
        return <Tag color={StatusColor[status]}>{statusCapitalized}</Tag>;
      },
      filters: [
        {
          text: 'Completed',
          value: 'completed',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Failed',
          value: 'failed',
        },
      ],
    },
  ];

  const settleMap = (data) => {
    // console.log('[DesktopTable.js] settleMap data', data);
    const record = data;
    const mapped = record.map((d, i) => {
      const o = {
        ...d,
        key: d.id,
        settlementDate: d.settlementDate ? moment(d.settlementDate).utc().format('MM-DD-YYYY') : '',
        createdAt: d.createdAt ? moment(d.createdAt).utc().format('MM-DD-YYYY') : '',
        totalSettlementFees: d.totalSettlementFees || 0,
        grossSettlement: d.grossSettlement || 0,
        adjustments: d.totalCharges || 0,
        netDeposit: d.netSettlement || 0,
      };

      return o;
    });
    return mapped;
  };

  const settleDataSource = settleMap(settlements.settlements);
  // console.log('[DesktopTable.js] settleDataSource', settleDataSource, processor);

  const items = [
    {
      label: (
        <span style={{ color: '#541388 !important', fontWeight: 600, fontSize: token.fontSizeLG }}>
          Transactions
        </span>
      ),
      key: '1',
      children: (
        <Table
          columns={columns}
          loading={transactions.loading}
          size="small"
          bordered
          onExpand={onExpand}
          expandedRowKeys={expandedKeys}
          expandable={{
            expandedRowRender: (record) => (
              <TransactionDescription
                record={record}
                // truncateString={truncateString}
              />
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CaretDownFilled
                  style={{ color: token.colorLink }}
                  onClick={(e) => onExpand(record, e)}
                  data-testid="shrinkedTest"
                />
              ) : (
                <CaretRightFilled
                  style={{ color: token.colorLink }}
                  onClick={(e) => onExpand(record, e)}
                  data-testid="expandTest"
                />
              ),
          }}
          dataSource={dataSource}
          onChange={handleTableChange}
          pagination={{
            total: transactions.totalCount,
            size: 'default',
            showSizeChanger: true,
            current: currentPage,
          }}
          data-testid="desktopTableTest"
        />
      ),
    },
    userData.paymentGateway === 'Finix' && {
      label: (
        <span style={{ color: '#541388 !important', fontWeight: 600, fontSize: token.fontSizeLG }}>
          Settlements
        </span>
      ),
      key: '2',
      children: (
        <Table
          columns={settlementsColumns}
          loading={loadSettlements}
          size="small"
          bordered
          onExpand={onSettleExpand}
          expandedRowKeys={settleExpandedKeys}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CaretDownFilled style={{ color: token.colorLink }} onClick={(e) => onExpand(record, e)} />
              ) : (
                <Tooltip title="View Transaction Details">
                  <CaretRightFilled style={{ color: token.colorLink }} onClick={(e) => onExpand(record, e)} />
                </Tooltip>
              ),
            expandedRowRender: (record) => {
              return record.status === 'completed' ? (
                <FinixDetailsTable
                  item={record}
                  truncateString={truncateString}
                  loadSettlements={loadSettlements}
                  itemType={itemType}
                />
              ) : (
                <DetailsPending item={record} />
              );
            },
          }}
          dataSource={settleDataSource}
          onChange={handleSettleTableChange}
          pagination={{
            total: settlements.total,
            size: 'default',
            showSizeChanger: true,
            current: settlePage,
          }}
          rowSelection={{
            hideSelectAll: true,
            renderCell: (checked, record, index, originNode) => {
              return record.status === 'completed' ? originNode : null;
            },
            onSelect: handleSettlementSelect,
          }}
          // data-testid="desktopTableTest"
        />
      ),
    },
  ];
  return (
    <CardWithTitle bodyPadding="0px 0px 15px 0px" className="transactionsTour">
      <StyledTabs
        defaultActiveKey="1"
        activeKey={activeTab.current}
        onTabClick={onTabClick}
        size="large"
        items={items}
        tabBarExtraContent={
          <Button
            icon={<PlusSquareOutlined style={{ fontSize: '14px', color: styledTheme.colors.white }} />}
            type="primary"
            height={36}
            fontSize={16}
            padding1={1}
            padding2={15}
            bottom={2}
            right={10}
            data-modal="transaction"
            onClick={() => {
              setDrawerVisible(true);
              setShowTransactionPanel(true);
            }}
            data-testid="transactionPanelTest"
          >
            ADD A TRANSACTION
          </Button>
        }
      />
    </CardWithTitle>
  );
};

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    margin-left: 20px !important;
    font-weight: 500;
  }
`;

export default DesktopTable;
