import axios from 'axios';
import Jsona from 'jsona';
import store from '../../../store';
import { refreshToken } from '../../aws/cognito';

const dataFormatter = new Jsona();

const source = axios.CancelToken.source();

const checkRefreshToken = async () => {
  const refreshed = await refreshToken();
  if (refreshed) {
    store().store.dispatch({
      type: 'UPDATE_USER_COGNITO_TOKENS',
      cognitoTokens: refreshed,
    });
    return refreshed;
  }
};

export const createLead = async (data = { unitCount: 1, email: 'example@email.com' }) => {
  try {
    const dataObject = {
      last_name: data.email.split('@')[0],
      email: data.email,
      lead_source_id: 9000322784,
      custom_field: { cf_number_of_units: data.unitCount },
    };

    if (localStorage.getItem('apid')) dataObject.custom_field.cf_affiliate_id = localStorage.getItem('apid');

    const results = await axios({
      method: 'post',
      url: process.env.REACT_APP_FRESHSALES_URL, //'https://payrent.freshsales.io/api/leads', //
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_FRESHSALES_KEY,
      },
      data: dataObject,
    });

    return results;
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log(err.message);
    } else if (err.response) {
      // client received an error response (5xx, 4xx)
      //console.log(err.response);
      return err.response;
    } else if (err.request) {
      // client never received a response, or request never left
      //console.log(err.request);
      return err.request;
    } else console.error(err);
  }
  source.cancel('Request canceled.');
};

export const attachPolicy = async (identityId, cognitoToken) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const data = {
      data: {
        type: 'default',
        attributes: {
          identityId,
        },
      },
    };
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/ws-policy`,
      data,
    };
    const res = await axios(options);
    return res;
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const documentsPresignedUrl = async (cognitoToken, ba, action, filename, type, ext, path) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    let url = '';
    if (action === 'download' || action === 'delete') {
      url = `${process.env.REACT_APP_API_URL_V3}/documents/file?action=${action}&id=${ba}&type=${type}&path=${path}`;
    }
    if (type === 'insurance') {
      url = `${process.env.REACT_APP_API_URL_V3}/documents/file?action=${action}&filename=${filename}&id=${ba}&type=${type}&ext=${ext}&path=${path}`;
    } else {
      url = `${process.env.REACT_APP_API_URL_V3}/documents/file?action=${action}&filename=${filename}&id=${ba}&type=${type}&path=${path}`;
    }

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const sendMessage = async (cognitoToken, data) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/users/messaging/send`,
      data,
    };

    return await axios(options);
  } catch (err) {
    console.error(err);
    throw err.response;
  }
};

export const unsubscribe = async (token) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/vnd.api+json',
        Accept: 'application/vnd.api+json',
      },
      url: `${process.env.REACT_APP_API_URL_V3}/unsubscribe/${token}`,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (error) {
    const { response } = error;
    throw response;
  }
};

export const createPaymentInstrument = async (cognitoToken, data, replace = false) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  const type = replace ? 'replace' : 'new';
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/finix/payment-instruments?type=${type}`,
      data,
    };

    const res = await axios(options);
    // console.log('[common/create.js] ƒ createMerchantPaymentInstrument result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    throw err.response;
  }
};

export const createMerchantPaymentInstrument = async (cognitoToken, data, replace = false) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  const type = replace ? 'replace' : 'new';
  try {
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/finix/merchants/payment-instruments?type=${type}`,
      data,
    };

    const res = await axios(options);
    // console.log('[common/create.js] ƒ createMerchantPaymentInstrument result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response;
  }
};

export const createFinixBuyerIdentity = async (cognitoToken, data) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;

  try {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL_V3}/finix/identities`,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data,
    };

    const res = await axios(options);
    // console.log('[api.js] ƒ createFinixMerchantIdentity result', res);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.error(err);
    throw err.response;
  }
};

export const createSessionLog = async (cognitoToken, log) => {
  const refreshed = await checkRefreshToken();
  const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
  try {
    const { id, ...rest } = log;
    const data = {
      data: {
        type: 'default',
        id: id,
        attributes: {
          ...rest,
        },
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/session/logs`,
      data,
    };

    await axios(options);
  } catch (err) {
    console.error(err);
    throw err.response;
  }
};

export const createPlaidFinixProcessorToken = async (cognitoToken, data) => {
  try {
    const refreshed = await checkRefreshToken();
    const token = refreshed ? refreshed.idToken.jwtToken : cognitoToken;
    const jsonaData = {
      type: 'default',
      data: {
        attributes: data,
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL_V3}/plaid/finix`,
      data: jsonaData,
    };

    const res = await axios(options);
    return dataFormatter.deserialize(res.data);
  } catch (err) {
    console.log(err);
    throw err.response;
  }
};
